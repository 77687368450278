import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
// import AddToPhotosOutlinedIcon from '@mui/icons-material/AddToPhotosOutlined';
// import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined';
// import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
// import AlbumOutlinedIcon from '@mui/icons-material/AlbumOutlined';
// import SwitchCameraOutlinedIcon from '@mui/icons-material/SwitchCameraOutlined';
// import SwitchLeftOutlinedIcon from '@mui/icons-material/SwitchLeftOutlined';
// import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

const Menuitems = [
  {
    title: "Dashboard",
    icon: DashboardOutlinedIcon,
    href: "/dashboard",
  },
  {
    title: "Manage Clients",
    icon: PeopleOutlineIcon,  
    href: "/clients",
  },
  // {
  //   title: "Autocomplete",
  //   icon: AddToPhotosOutlinedIcon,
  //   href: "/form-elements/autocomplete",
  // },
  // {
  //   title: "Buttons",
  //   icon: AspectRatioOutlinedIcon,
  //   href: "/form-elements/button",
  // },
  // {
  //   title: "Checkbox",
  //   icon: AssignmentTurnedInOutlinedIcon,
  //   href: "/form-elements/checkbox",
  // },
  // {
  //   title: "Radio",
  //   icon: AlbumOutlinedIcon,
  //   href: "/form-elements/radio",
  // },
  // {
  //   title: "Slider",
  //   icon: SwitchCameraOutlinedIcon,
  //   href: "/form-elements/slider",
  // },
  // {
  //   title: "Switch",
  //   icon: SwitchLeftOutlinedIcon,
  //   href: "/form-elements/switch",
  // },
  // {
  //   title: "Form",
  //   icon: DescriptionOutlinedIcon,
  //   href: "/form-layouts/form-layouts",
  // },
];

export default Menuitems;
