import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  FormControl,
  MenuItem,
  Select
} from "@mui/material";

import SubscriptionsTalbe from "./subscriptionsTable";

const MONTHS = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

function years() {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = 2023; year <= currentYear; year++) {
    years.push(year);
  }

  return years;
}

const SubscriptionsContainer = () => {
  const [date, setDate] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });

  return (
    <Card variant="outlined">
      <CardContent
        sx={{
          // height: "425px",
          overflow: "auto",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        <Box
          sx={{
            display: {
              sm: "flex",
              xs: "block",
            },
            alignItems: "flex-start",
          }}
        >
          <Box>
            <Typography
              variant="h3"
              sx={{
                marginBottom: "0",
              }}
              gutterBottom
            >
              Clients Subscriptions
            </Typography>
          </Box>

          <Box
            sx={{
              marginLeft: "auto",
              mt: {
                lg: 0,
                xs: 2,
              },
            }}
          >
            <FormControl variant="standard" sx={{ minWidth: 120 }}>
              <Select
                labelId="select"
                id="select"
                value={date.month}
                onChange={(e) =>
                  setDate((prev) => ({
                    ...prev,
                    month: e.target.value,
                  }))
                }
                label="Select "
                required
              >
                {Object.keys(MONTHS).map((i, index) => {
                  return (
                    <MenuItem value={i} key={index}>
                      {MONTHS[i]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl variant="standard" sx={{ minWidth: 120, mx: 2 }}>
              <Select
                labelId="select"
                id="select"
                value={date.year}
                onChange={(e) =>
                  setDate((prev) => ({
                    ...prev,
                    year: e.target.value,
                  }))
                }
                label="Select "
                required
              >
                {years().map((year, index) => {
                  return (
                    <MenuItem value={year} key={index}>
                      {year}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            overflow: "auto",
            mt: 3,
          }}
        >
          <SubscriptionsTalbe date={date} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default SubscriptionsContainer;
