import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Box,
  TableCell,
  TableRow,
  ListItemIcon,
  Avatar,
  Chip,
  Drawer,
  Stack,
  Divider,
  TextField,
  Button,
  Alert,
  CircularProgress,
} from "@mui/material";
import { API } from "../../../api";
import EmailIcon from "@mui/icons-material/Email";
import MessageIcon from "@mui/icons-material/Message";
import CloseIcon from "@mui/icons-material/Close";
import ScrollBar from "../../../components/ScrollBar";
import { toastMessage } from "../../../utils/toast";
import { useState } from "react";

const UserListTile = ({ user }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();

  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [openSidebar, setOpenSidebar] = useState(false);

  const handleOpenSidebar = () => {
    handleClose();
    setOpenSidebar(true);
  };

  const handleCloseSidebar = () => {
    setOpenSidebar(false);
    setSubject("");
    setMessage("");
  };
  const [state, setState] = useState({
    loading: false,
    error: null,
  });

  const handleSubmit = async () => {
    try {
      setState((prev) => ({ ...prev, loading: true, error: null }));
      await API.post("/users/send-email", {
        email: user.email,
        subject,
        message,
      });
      toastMessage(`Email has sent successfully to <b>${user.email}</b>`);
      handleCloseSidebar();
    } catch (error) {
      if (
        error?.response?.data?.message &&
        Array.isArray(error?.response?.data?.message)
      ) {
        error.message = error?.response?.data?.message?.join(", ");
      } else {
        error.message = error?.response?.data?.message;
      }
      setState((prev) => ({
        ...prev,
        error: error.message,
      }));
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  return (
    <>
      <TableRow key={user?._id}>
        <TableCell>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Avatar
              sx={{
                width: "35px",
                height: "35px",
                backgroundColor: theme.palette.primary.main,
              }}
              src={user?.profilePic}
            >
              {user?.firstName?.charAt(0)}
            </Avatar>
            <Box>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "600",
                }}
              >
                {user?.firstName + " " + user?.lastName}
              </Typography>
              <Typography
                color="textSecondary"
                sx={{
                  fontSize: "13px",
                }}
              >
                {user?.email}
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell align="center">
          <Typography color="textSecondary" variant="h6">
            {user?.phoneNumber || "N/A"}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="h6">{user?.accountType}</Typography>
        </TableCell>
        <TableCell>
          <Chip
            sx={{
              pl: "4px",
              pr: "4px",
              backgroundColor: user?.verified ? "success.main" : "error.main",
              color: "#fff",
            }}
            size="small"
            label={user?.verified ? "True" : "False"}
          ></Chip>
        </TableCell>
        <TableCell>
          <Typography color="primary" variant="h6">
            <Box sx={{ marginLeft: "auto" }}>
              <IconButton
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertOutlinedIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{ "aria-labelledby": "long-button" }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <MenuItem key="email" onClick={() => handleOpenSidebar(user)}>
                  <ListItemIcon>
                    <EmailIcon />
                  </ListItemIcon>
                  Email Client
                </MenuItem>
                <MenuItem key="email" onClick={() => {}}>
                  <ListItemIcon>
                    <MessageIcon />
                  </ListItemIcon>
                  Message Client
                </MenuItem>
              </Menu>
            </Box>
          </Typography>
        </TableCell>
      </TableRow>
      <Drawer
        anchor="right"
        open={openSidebar}
        onClose={handleCloseSidebar}
        PaperProps={{
          sx: { width: 320, border: "none", overflow: "hidden" },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Email Client
          </Typography>
          <IconButton onClick={handleCloseSidebar}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        {state.error && (
          <Alert variant="standard" severity="error" sx={{ mt: 2 }}>
            {state.error}
          </Alert>
        )}
        <ScrollBar>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              m: 3,
              p: 2,
              boxShadow: "5px 5px 34px 5px #ddd",
              borderRadius: "5px",
            }}
          >
            <Avatar
              sx={{
                width: "35px",
                height: "35px",
                backgroundColor: theme.palette.primary.main,
              }}
              src={user?.profilePic}
            >
              {user?.firstName?.charAt(0)}
            </Avatar>
            <Box>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "600",
                }}
              >
                {user?.firstName + " " + user?.lastName}
              </Typography>
              <Typography
                color="textSecondary"
                sx={{
                  fontSize: "13px",
                }}
              >
                {user?.email}
              </Typography>
            </Box>
          </Box>
          <Stack spacing={3} sx={{ p: 3 }}>
            <TextField
              label="Subject"
              color="secondary"
              fullWidth
              required
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
            <TextField
              label="Message"
              color="secondary"
              fullWidth
              required
              value={message}
              multiline
              rows={8}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Stack>
          <Box sx={{ p: 3 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              onClick={handleSubmit}
              variant="outlined"
              startIcon={
                state.loading ? (
                  <CircularProgress size={18} color="inherit" />
                ) : undefined
              }
            >
              Submit
            </Button>
          </Box>
        </ScrollBar>
      </Drawer>
    </>
  );
};

export default UserListTile;
