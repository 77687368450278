import React, { useState } from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  TablePagination,
} from "@mui/material";
import { useFetcher } from "../../../api";
import DataChecker from "../../../components/global/DataChecker";

const SubscriptionsTable = ({ date }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const { data, isLoading, isError } = useFetcher(
    `/subscriptions?perPage=${rowsPerPage}&page=${page + 1}&month=${
      date.month
    }&year=${date.year}`
  );

  const subscriptions = data?.data;

  return (
    <DataChecker
      title="Subscriptions"
      isLoading={isLoading}
      isError={isError}
      isEmpty={subscriptions?.length === 0}
    >
      <Table
        aria-label="simple table"
        sx={{
          mt: 3,
          whiteSpace: "nowrap",
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography color="textSecondary" variant="h6">
                Client Info
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="h6">
                Subscription Name
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="h6">
                Status
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography color="textSecondary" variant="h6">
                Amount Paid
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="h6">
                Valid For
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subscriptions?.map((subscription) => (
            <TableRow key={subscription?._id}>
              <TableCell>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "600",
                      }}
                    >
                      {subscription?.user?.firstName +
                        " " +
                        subscription?.user?.lastName}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      {subscription?.user?.email}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell>
                <Typography color="textSecondary" variant="h6">
                  {subscription?.type === "Plan"
                    ? subscription?.itemName + " " + subscription?.type
                    : subscription?.itemName}
                </Typography>
              </TableCell>
              <TableCell>
                <Chip
                  sx={{
                    pl: "4px",
                    pr: "4px",
                    backgroundColor:
                      subscription?.status === "Active"
                        ? "success.main"
                        : subscription?.status === "Inactive"
                        ? "primary.main"
                        : "error.main",
                    color: "#fff",
                  }}
                  size="small"
                  label={subscription?.status}
                ></Chip>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h6">{subscription?.price} Rwf</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography color="primary" variant="h6">
                  {subscription?.amount}{" "}
                  {subscription?.amount > 1 ? "Years" : "Year"}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data?.pagination?.total || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </DataChecker>
  );
};

export default SubscriptionsTable;
