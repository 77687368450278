import { Alert } from "@mui/material";
import { toast } from "react-hot-toast";

export const toastMessage = (message, { isError, isWarning } = {}) => {
  return toast(() => (
    <Alert
      variant="filled"
      severity={isError ? "error" : isWarning ? "info" : "success"}
    >
      <div dangerouslySetInnerHTML={{ __html: message }}></div>
    </Alert>
  ));
};
