import React, { useState } from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  TablePagination,
} from "@mui/material";
import { useFetcher } from "../../../api";
import DataChecker from "../../../components/global/DataChecker";
import { formatDate } from "../../../utils/formatDate";

const PaymentsTable = ({ date }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const { data, isLoading, isError } = useFetcher(
    `/payment?perPage=${rowsPerPage}&page=${page + 1}&month=${
      date.month
    }&year=${date.year}`
  );

  const payments = data?.data;

  return (
    <DataChecker
      title="Payments"
      isLoading={isLoading}
      isError={isError}
      isEmpty={payments?.length === 0}
    >
      <Table
        aria-label="simple table"
        sx={{
          mt: 3,
          whiteSpace: "nowrap",
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography color="textSecondary" variant="h6">
                Transaction Id
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="h6">
                Paid Client
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="h6">
                Paid Items
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography color="textSecondary" variant="h6">
                Amount Paid
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography color="textSecondary" variant="h6">
                Payment Method
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="h6">
                Transaction Status
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="h6">
                Transaction Date
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments?.map((payment) => (
            <TableRow key={payment?._id}>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                >
                  {payment?.transactionId}
                </Typography>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "600",
                      }}
                    >
                      {payment?.user?.firstName + " " + payment?.user?.lastName}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      {payment?.user?.email}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell>
                <Typography color="textSecondary" variant="h6">
                  {payment?.paymentPlans?.map((plan) => (
                    <Typography key={plan} variant="h6">
                      {plan}
                    </Typography>
                  ))}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h6">{payment?.amount} Rwf</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h6">{payment?.paymentMethod}</Typography>
              </TableCell>
              <TableCell align="center">
                <Chip
                  sx={{
                    pl: "4px",
                    pr: "4px",
                    backgroundColor:
                      payment?.status === "Success"
                        ? "success.main"
                        : payment?.status === "Pending"
                        ? "primary.main"
                        : "error.main",
                    color: "#fff",
                  }}
                  size="small"
                  label={payment?.status}
                ></Chip>
              </TableCell>
              <TableCell>
                <Typography color="textSecondary" variant="h6">
                  {formatDate(payment?.createdAt)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data?.pagination?.total || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </DataChecker>
  );
};

export default PaymentsTable;
