import React from "react";
import { Grid, Box } from "@mui/material";

import {
  PaymentsContainer,
  SubscriptionsContainer,
  DailyActivities,
} from "./components";

const Dashboard1 = () => {
  // 2

  return (
    <Box>
      <Grid container spacing={0}>
        <Grid item xs={12} lg={4}>
          <DailyActivities />
        </Grid>
        <Grid item xs={12} lg={8}>
          <SubscriptionsContainer />
        </Grid>
        <Grid item xs={12} lg={12}>
          <PaymentsContainer />
        </Grid>
        {/* <BlogCard /> */}
      </Grid>
    </Box>
  );
};

export default Dashboard1;
